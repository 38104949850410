import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { fonts } from '../../constants/mainConstants';
import cryingRobot from "../../images/cryingRobot.png";

const styles = {
    mainDiv:{
        width: '100vw',
        height: '100vh',
        textAlign: 'center',
        direction: 'ltr'
    },
    title:{
        margin: 'auto',
        paddingTop: '2vmin',
        fontFamily: fonts.get('english'),
        color: 'darkslategray',
        fontSize: '5vmin',
        direction: 'ltr'
    },
    image:{
        height: '50vmin'
    },
    steps:{
        width: '40vw',
        margin: 'auto',
        fontFamily: fonts.get('english'),
        color: 'darkslategray',
        textAlign: 'left',
        direction: 'ltr'
    },
    li:{
        padding: '0.2vmin',
        fontSize: '2vmin'
    }
  };

class ErrorScreen extends Component {
    constructor(props){
        super();
        this.state = {
            
        }
    }

    render(){
     
        return(
            <div className={this.props.classes.mainDiv}>
                <h1 className={this.props.classes.title}>Something went wrong</h1>
                <img src={cryingRobot} className={this.props.classes.image} alt='תמונה'/>
                <div className={this.props.classes.steps}>
                    <h2>Follow these steps:</h2>
                    <ul>
                        <li className={this.props.classes.li}>Reload the page</li>
                        <li className={this.props.classes.li}>If didn't work, check network connection</li>
                        <li className={this.props.classes.li}>Try to figure it out yourself</li>
                        <li className={this.props.classes.li}>If nothing works, then you may ask mendy for help (it's probably not his fault)</li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(ErrorScreen);