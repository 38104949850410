import React, { Component } from 'react';
import { Map, List } from 'immutable';
import { fonts } from '../../constants/mainConstants';
import { Collapse, CardActions } from '@material-ui/core';
import { GetIcon } from "../../services/mainServices";

const styles = Map({
    selected: {
        width:'100%',
        direction: 'ltr',
        backgroundColor: 'black',
        color: 'white',
        position: 'relative',
        top: 0,
        zIndex: 1,
        maxHeight: '5vh'
    },
    markresourcesAmount:{
        display: 'inline-block',
        cursor:'pointer',
        position: 'absolute',
        top:'35%',
        right:'1vw',
        direction:'rtl',
        fontFamily: fonts.get('hebrew')
    },
    cardActionsdiv: {
        height: '7vh'
    },
    cardActions: {
        marginRight:'80%',
        height: '-webkit-fill-available'
    }
})

class SystemStatusCollapse  extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render(){
        const { actions = List([]), isOpen} = this.props;
        return (
            <Collapse in={isOpen} style={styles.get('selected')} >
                 <div style={styles.get('markresourcesAmount')}>
                     {'המערכת פועלת במצב לא מקוון'}
                 </div>
                {actions && 
                <div style={styles.get('cardActionsdiv')}>
                    <CardActions style={styles.get('cardActions')}>
                        {actions.toJS().map((action, key) => <GetIcon key={key} {...action}/>)}
                    </CardActions>
                </div>}
            </Collapse>
          );
    }
}

export default SystemStatusCollapse;