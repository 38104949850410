import { Map } from "immutable";

const mainReducer = (state={title: '', datesTitle: '', searchWord: '', searchAmount: '', searchInfo: Map({}), searchWordHandler:()=>{}}, action) =>{
    switch(action.type){
        case("CHANGE_TITLE"):{
            return({...state, title: action.payload.title})
        }
        case("CHANGE_DATES_TITLE"):{
            return({...state, datesTitle: action.payload.dates})
        }
        case("CHNAGE_SEARCH_WORD"):{
            return({...state, searchWord: action.payload.searchWord})
        }
        case("CHNAGE_SEARCH_AMOUNT"):{
            return({...state, searchAmount: action.payload.searchAmount})
        }
        case("CHNAGE_SEARCH_AMOUNT_HANDLER"):{
            const info = Map({'|': 'הוספת פרמטר סינון'})
            return({...state,
                    searchWordHandler: action.payload.searchWordHandler,
                    searchInfo: Map({...info.toJS(),
                                     ...(action.payload.searchInfo ? action.payload.searchInfo.toJS() : {})})
            })
        }
        default:{
            return (state);
        }
    }
}

export {mainReducer};