import React from 'react';
import { IconButton } from "@material-ui/core";
import { translation, fonts, extraPresses, keyCodes, Globals, Dictionary, Globals_details } from '../constants/mainConstants';
import { List, Map, Set, fromJS } from 'immutable';
import { store } from "../index";
import { createSnackbar, changeSysMode, changeConfirmationDialogStatus } from '../actions/mainActions';
import inViewport from "in-viewport";
import { pages } from '../constants/menuConstants';
import { colors } from '../constants/themeConstants';


console.obj = (obj) => {
  const isImmutable = List.isList(obj) || Map.isMap(obj) || Set.isSet(obj);
  isImmutable && console.log('Immutable:')
  console.log(isImmutable ? obj.toJS() : obj)
}

const getTypesObjFromEnum = (category) => {
  return(
    fromJS(Globals).get(category).valueSeq()
    .map(platformId => ({_id: platformId,
      name: fromJS(Dictionary).getIn([category, platformId.toString()]),
      display_name:fromJS(Dictionary).getIn([category, platformId.toString()])})).toJS()
      )
    }
    
    const getTypesObjFromEnumDetails = (category) => {
  return(fromJS(Globals_details).get(category))
}

const isInViewport = (id) => inViewport(document.getElementById(id));

const scrollToClass = elmntClassName => () => {
  // window.scrollIntoView(elmntClassName.offsetTop, elmntClassName.offsetTop);
  const myDiv = document.getElementsByClassName(elmntClassName)[0];
  myDiv.scrollIntoView({ behavior: "smooth", block: "end" });
};

const GetIcon = ({title, onClick, Icon, style={}, condition, disabled=false}) => {
  if(condition){
    return(
      <IconButton title={title} onClick={onClick} disabled={disabled}>
              <Icon className='svgLinesFast' style={style ? {color: 'black', visibility: disabled ? 'hidden' : 'visible', ...style} : {color: 'black'}}/>
          </IconButton>
      )
    }
    return null;
  }
  
  const getIcon = ({title, onClick, Icon, style={}, condition=true, disabled=false, className=''}) => {
    if(condition){
      return(
        <div className={className} style={{width: '5vw', textAlign: 'center'}}>
          <IconButton style={{width: 'auto', height: 'auto', padding: '0.3vmin'}} title={title} onClick={onClick} disabled={disabled}>
              <Icon className='svgLinesFast' style={style ? {color: colors.get('buttons'), visibility: disabled ? 'hidden' : 'visible', height: '4vmin', width: '4vmin', minHeight: '30px', minWidth: '30px', ...style} : {color: colors.get('buttons'), height: '4vmin', width: '4vmin'}}/>
          </IconButton>
          <span className='iconText' style={{visibility: disabled ? 'hidden' : 'visible', flexDirection: 'column', textAlign: 'WebkitCenter', fontSize: '1.5vmin', fontFamily: getFont(title)}}>{title}</span>
        </div>
      )
    }
    return null;
  }
  
  const stringShorten = (string, size) => string && string.length > size ? `${string.slice(0, size)}...` : string
  
  const translateToHebrew = (word) => word && translation.get(word.toString().toLowerCase()) ? translation.get(word.toLowerCase()) : word;
  
  const isHebrewString = (word) => word.search(/[א-ת]/) >= 0;
  
  const isArabicString = (word) => {
    const arabic = /[\u0600-\u06FF]/;
    return arabic.test(word)
  }
  
  const getFont = (word) => {
    return(
      word ? 
      isHebrewString(translateToHebrew(word.toString())) ? 
      fonts.get('hebrew') :
      isArabicString(word.toString()) ?
      fonts.get('arabic') :
      fonts.get('english') :
      ''
      )
    }
    
    const getKey = (immObj, val) => {
      return immObj.keySeq().get(immObj.valueSeq().indexOf(val))
    }
    
    const snackbarMessage = (type, message) => {
      store.dispatch(createSnackbar({snackbarMessage: message, snackbarType: type, isSnackbarOpen: false}, 'SNACKBAR'))
      setImmediate(()=>store.dispatch(createSnackbar({snackbarMessage: message, snackbarType: type, isSnackbarOpen: true}, 'SNACKBAR')));
      setTimeout(()=>store.dispatch(createSnackbar({snackbarMessage: message, snackbarType: type, isSnackbarOpen: false}, 'SNACKBAR')), 5000);
    }

    const confirmationDialog = (isOpen, content, confirm, actions) => {
      store.dispatch(changeConfirmationDialogStatus({
        isConfirmationDialogOpen: isOpen,
        confirmationDialogContent: content,
        confirmationDialogConfirm: confirm,
        confirmationDialogActions: actions}, 'CONFIRMATION_DIALOG'))
    }
    
    const changeSystemMode = (val) => {
      store.dispatch(changeSysMode(val))
    }
    
    const getIsSysOnline = () => {
      return store.getState().general.isSysOnline;
    }
    
    const selectAllListener = (func, pressedKey, extraPressed, extraConditions) => (e) => {
      const isKeyMatch = e.keyCode === keyCodes.get(pressedKey.toLowerCase());
      const isExtraKeysMatch =  fromJS(extraPressed).every(button => e[extraPresses.get(button)]);
      const isExternalCondValid = extraConditions.every(cond => cond());
      if(isKeyMatch && isExtraKeysMatch && isExternalCondValid){
        e.preventDefault();
        func();
      }
    } 
    
    const createKeyDownEvent = (func, pressedKey, extraPressed, extraConditions=List([() => true])) => {
      const keyDownHandler = selectAllListener(func, pressedKey, extraPressed, extraConditions)
      document.addEventListener('keydown', keyDownHandler)
      return keyDownHandler;
    }
    
    const relativeTime = (date) => {
      const currDate = new Date();
      const relTime = new Intl.RelativeTimeFormat(window.clientInformation.languages[1], { style: 'long', numeric: 'auto' })
      const yearGap = currDate.getFullYear() - date.getFullYear()
      const monthGap = currDate.getMonth() - date.getMonth()
      const dayGap = currDate.getDate() - date.getDate()
      const hourGap = currDate.getHours() - date.getHours()
      const minuteGap = currDate.getMinutes() - date.getMinutes()
      const secondGap = currDate.getSeconds() - date.getSeconds()
      return(
        yearGap ?
        relTime.format(-yearGap, 'year') :
        monthGap ?
        relTime.format(-monthGap, 'month') :
        dayGap ?
        relTime.format(-dayGap, 'day') :
        hourGap ?
        relTime.format(-hourGap, 'hour') :
        minuteGap ?
        relTime.format(-minuteGap, 'minute') :
        secondGap ?
        relTime.format(-secondGap, 'second') :
        relTime.format(-0, 'second')
        )
      }
      
      const deepSearch = (searchFrom, words, deep, replacer , keyWords) => {
        try{
          const searchWords = words.split('|').map(word => word.trim());
          const keywordsFilter = (fields) => keyWords ? fromJS(JSON.parse(JSON.stringify(fields.toJS(), keyWords.toJS()))) : fields;
          const translatedValue = (value, key, fields) => replacer  && replacer.has(key) ? replacer.get(key)(value, fields) : value;
          const isIncludesSearchWords = (obj, word) => {
            const filteredKeywords = keywordsFilter(obj);
            return filteredKeywords.some((value, key) => 
                value || replacer.has(key) ? 
                  typeof value !== 'object' || replacer.has(key)? 
                    (translatedValue(value, key, filteredKeywords) || '').toString().toLowerCase().includes(word.toLowerCase()) :
                    isIncludesSearchWords(value, word):
                false
              )
            }
          const getObject = (obj, i) => {
            return i < deep ? obj.map(o=>getObject(o, i+1)) : obj.filter(fields =>
              searchWords.every(word =>
                isIncludesSearchWords(fields, word)
            ))
          }
          return getObject(searchFrom, 0)
        }
        catch(e){
          console.error(e)
          return searchFrom;
        }
      }

//       const deepSearch = (searchFrom, words, isRec, translations, keyWords) => {
//         const searchWords = words.split('|').map(word => word.trim());
//         const checkIsObject = (val) => val && typeof val === 'object';
//         const hasValInItem = (field, key, word) => field && field.toString().toLowerCase().includes(word.toLowerCase())
//         const hasValInTranslation = (field='undefined', key, word, obj) => {
//           return translations && translations.get(key) && translations.get(key)(field, obj).includes(word.toLowerCase())
//         }
//         const hasVal = (field, key, word, obj) => {
//           return checkIsObject(field) && !(toString.call(field.toJS()).includes('Array') && typeof field.toJS()[0] !== 'object') ? field.some((f, k) => hasVal(f, k, word, field)) : (translations && translations.get(key)) ? hasValInTranslation(field, key, word, obj) : hasValInItem(field, key, word, obj);
//         }
//         const sorting = (val, word) =>  {
//           return(
//         val.map((val, i) => checkIsObject(val) &&
//                                   val.valueSeq().every(item => checkIsObject(item)) ?
//                                     val.valueSeq().every(item => item.valueSeq().every(subItem => checkIsObject(subItem))) ? 
//                                       deepSearch(val, words, true, translations, keyWords) :
//                                       val.filter(fields => fields && searchWords.every(word => (keyWords ? fromJS(JSON.parse(JSON.stringify(fields.toJS(), keyWords.toJS()))) : fields).some((value, key) => hasVal(value, key, word, val)))) :
//                                     val.some((field, key) => field ? hasVal(field, key, word, val) : false)  ?
//                                       val :
//                                       val.valueSeq().remove(i)
//                                   )
//       )
//     }
//     return(
//       !isRec ? 
//       sorting(List([searchFrom])).get(0) :
//       sorting(searchFrom)     
//     )
// }

const isScrollInBottom = e => {
  return e.target.scrollHeight - (e.target.scrollTop + 500) <= e.target.clientHeight;
}

const getCurrURL = () => {
  const urlArr = window.location.href.split('/');
  return urlArr[urlArr.length - 1]
}

const clearCookie = () => {
  document.cookie.split(";").forEach(c => document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"));
}

const compareObjects = (firtsObj, secondObj) => {
  return JSON.stringify(firtsObj) === JSON.stringify(secondObj)
}

const redirect = (url) => () => window.location.href = url;

const logOut = () => {
  clearCookie();
  redirect(pages.get(7).get('url'))();
  sessionStorage.clear();
}

const getFromLocalStorage = (path, defaultReturn=[]) => {
  if(sessionStorage){
    const immSessionStorage = Map({...sessionStorage})
    const data = immSessionStorage.find((val,key) => key.endsWith(path));
    const currObj = data ? JSON.parse(data).payload : defaultReturn
    return currObj
  }
  return defaultReturn;
}

export {translateToHebrew};
export {isHebrewString};
export {isArabicString};
export {getFont};
export {deepSearch};
export {createKeyDownEvent};
export {getKey};
export {snackbarMessage};
export {GetIcon};
export {isInViewport};
export {getIcon};
export {stringShorten};
export {getCurrURL};
export {redirect};
export {logOut};
export {changeSystemMode};
export {getIsSysOnline};
export {getFromLocalStorage};
export {isScrollInBottom};
export {compareObjects};
export {getTypesObjFromEnum};
export {getTypesObjFromEnumDetails};
export {relativeTime};
export {scrollToClass};
export {confirmationDialog};