import React, { Component } from 'react';
import { Map } from 'immutable';
import loginImg from '../../images/logoImg.png';
import Button from '@material-ui/core/Button';
import tank from '../../images/tank2.jpg';
import { fonts } from '../../constants/mainConstants';

const isMazi = process.env.REACT_APP_IS_MAZI==='true'
const styles = Map({
    loginScreen: {
        backgroundImage: `linear-gradient(to top right,rgba(255,255,255,0.5) 30%, rgb(123, 80, 224)),url(${loginImg})`,
        height:'100%',
        backgroundSize:'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection:'column',
        textAlign: 'center',
        width: '100vw'
    },
    form: {
        marginTop:'20vh',
        width: '50%',
        margin: 'auto',
        padding: '2vmin',
        borderRadius: '5vmin',
        background: isMazi ? '#4f4669e6': ``,
        backgroundImage:  isMazi ? `url(${tank})`: `linear-gradient(to top right,#28222280 30%, #7b50e0)`,
        backgroundSize: isMazi ? 'cover' : '',
        backgroundRepeat: isMazi ? 'no-repeat' : '',
    },
    inputWrapper: {
        padding:'4vmin'
    },
    input: {
        width: '30vw',
        height:'4vh',
        padding:'0.5vmin',
        fontSize:'2vmin',
        borderRadius: '1vmin',
        fontFamily: fonts.get('english')
    },
    button: {
        borderRadius:'10px',
        background: isMazi ? 'black':'green',
        color: 'white',
        width: isMazi ? '12vw': 'initial',
        height: isMazi ? '6.5vh' : 'initial',
        fontSize:isMazi ? '1.75vmax': 'initial',
        fontFamily: fonts.get('hebrew')
    }
})

class Login extends Component {
  constructor(){
    super();
    this.state={
    }
  }


  render() {
return(
        <div style={styles.get('loginScreen')}>
            <form style={styles.get('form')}
                  action='/auth'
                  method='post'>
                <div style={styles.get('inputWrapper')}>
                    <input type='text'
                           name='username'
                           dir='rtl'
                           placeholder='שם משתמש'
                           style={styles.get('input')}/>
                </div>
                <div style={styles.get('inputWrapper')}>
                    <input type='password'
                           name='password'
                           dir='rtl'
                           placeholder='סיסמא'
                           style={styles.get('input')}/>
                </div>
                <div style={styles.get('inputWrapper')}>
                    <Button type='submit'
                            variant="text"
                            color="primary"
                            style={styles.get('button')}>
                        כניסה
                    </Button>
                </div>
            </form>
        </div>
    )
  }
}

export default Login;
