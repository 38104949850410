const resourcesReducer = (state={}, action) =>{
    switch(action.type){
        case("CHANGE_DATA_RESOURCES"):{
            sessionStorage && action && action.data && 
                action.data.payload && sessionStorage.setItem('resources', JSON.stringify(action.payload.data));
            return({...state})
        }
        default:{
            return (state);
        }
    }
}

export {resourcesReducer};