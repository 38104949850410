import { Map, List, fromJS } from 'immutable';
import Globals from '../Global/json/enums.json'
import Globals_details from '../Global/json/enums_details';
import Dictionary from '../Global/json/dictionary.json'
import { getKey } from '../services/mainServices.js';
// const file = require('../../constants/mainConstants/globalsImporter.js');

const systemColorsPrimary = Map({
    // dark: '#000070',
    medium: '#ee5817',
    // light: '#7953d2'
})

// const systemColorsSecondary = Map({
//     dark: '#ac0800',
//     medium: '#e64a19',
//     light: '#ff7d47'
// })

const regExps = Map({
    url: /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
})

const translation = Map({
    status: 'סטטוס',
    main: 'ראשי',
    favorite: 'מועדפים',
    archive: 'ארכיון',
    stats: 'מדדים',
    battlemanagment: 'ניהול משימות',
    resources: 'משאבים',
    targets: 'מטרות',
    constitution: 'חוקה',
    type: 'סוג',
    edit: 'עריכה',
    delete: 'מחיקה',
    save: 'שמור',
    cancel: 'ביטול',
    subject_name: 'נושא',
    users: 'יוזרים',
    user: 'משתמש',
    platform: 'פלטפורמה',
    pending: 'בהמתנה',
    executed: 'הושלם',
    scheduled: 'מתוזמן',
    hobby: 'תחביב',
    critical: 'קריטי',
    photos: 'תמונות',
    image: 'תמונות',
    video: 'וידאו',
    link: 'לינקים',
    gardening: 'גינון',
    monitoring: 'ניטור',
    distribution: 'הפצה',
    text: 'טקסט',
    hashtag: 'האשטאג',
    linked: 'מקושרים',
    age: 'גיל',
    gender_name: 'מין',
    gender: 'מין',
    nation_name: 'מוצא',
    nation: 'מוצא',
    male: 'זכר',
    female: 'נקבה',
    amount_of_agents: 'מספר יוזרים',
    agents_facebook: 'יוזרים בפייסבוק',
    agents_twitter: 'יוזרים בטוויטר',
    agents_block_facebook: 'יוזרים חסומים בפייסבוק',
    agents_block_twitter: 'יוזרים חסומים בטוויטר',
    agents_active_facebook: 'יוזרים פעילים בטוויטר',
    agents_active_twitter: 'יוזרים פעילים בפייסבוק',
    search_posts: 'חיפוש פוסטים',
    search_groups: 'חיפוש קבוצות',
    search_people: 'חיפוש אנשים',
    search_photos: 'חיפוש תמונות',
    search_videos: 'חיפוש סרטונים',
    search_shop: 'חיפוש קניות',
    search_pages: 'חיפוש דפים',
    search_places: 'חיפוש מקומות',
    search_apps: 'חיפוש אפליקציות',
    search_events: 'חיפוש אירועים',
    search_links: 'חיפוש לינקים',
    arabic: 'ערבי',
    german: 'גרמני',
    french: 'צרפתי',
    english: 'אנגלי',
    campaigns: 'קמפיינים',
    subjects: 'נושאים',
    proxies: 'פרוקסים',
    albums: 'אלבומים',
    names:'שמות'

})

const extraPresses = Map({
    alt: 'altKey',
    ctrl: 'ctrlKey',
    shift: 'shiftKey'
})

const keyCodes = Map({
    0: 48,
    1: 49,
    2: 50,
    3: 51,
    4: 52,
    5: 53,
    6: 54,
    7: 55,
    8: 56,
    9: 57,
    a: 65,
    b: 66,
    c: 67,
    d: 68,
    e: 69,
    f: 70,
    g: 71,
    h: 72,
    i: 73,
    j: 74,
    k: 75,
    l: 76,
    m: 77,
    n: 78,
    o: 79,
    p: 80,
    q: 81,
    r: 82,
    s: 83,
    t: 84,
    u: 85,
    v: 86,
    w: 87,
    x: 88,
    y: 89,
    z: 90,
    f1:	112,
    f2:	113,
    f3:	114,
    f4:	115,
    f5:	116,
    f6:	117,
    f7:	118,
    f8:	119,
    f9:	120,
    f10: 121,
    f11: 122,
    f12: 123,
    comma: 188,
    dash: 189,
    period:	190
})

const fonts = Map({
    hebrew: 'Assistant',
    english: 'Montserrat',
    arabic: 'Markazi Text'
})

const formatTypes = Map({
    '1': List(['jpeg', 'jpg', 'exif', 'tif', 'tiff', 'gif', 'bmp', 'png', 'ppm', 'pgm', 'pbm', 'pnm', 'webp', 'hdr', 'heif', 'bat', 'bpg']),
    '5': List(['flv', 'avi', 'mov', 'mp4', 'mpg', 'wmv', '3gp', 'asf', 'rm']),
    '2': List(['xls', 'xlsx', 'csv', 'txt']),
    '3': List(['xls', 'xlsx', 'csv', 'txt']),
    '4': List(['xls', 'xlsx', 'csv', 'txt'])
})

const screenSizes = Map({
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 5
})

const buttonsColors = Map({
    refresh: 'rgb(2,53,90)',
    create: 'rgb(2,53,90)',
    add: 'rgb(2,53,90)',
    graph: 'rgb(2,53,90)'
})

const countriesIdToFlag = Map({
    1: Map({code: 'RU', displayName: 'רוסיה'}),
    2: Map({code: 'KZ', displayName: 'קזחסטן'}),
    3: Map({code: 'UA', displayName: 'אוקריאנה'}),
    4: Map({code: 'RO', displayName: 'רומניה'}),
    5: Map({code: 'AR', displayName: 'ארגנטינה'}),
    6: Map({code: 'BY', displayName: 'בלארוס'}),
    7: Map({code: 'BR', displayName: 'ברזיל'}),
    8: Map({code: 'KH', displayName: 'קמבודיה'}),
    9: Map({code: 'TD', displayName: "צ'אד"}),
    10: Map({code: 'CN', displayName: 'סין סים וירטואלי'}),
    11: Map({code: 'CI', displayName: 'חוף השנהב'}),
    12: Map({code: 'EG', displayName: 'מצרים'}),
    13: Map({code: 'EE', displayName: 'אסטוניה'}),
    14: Map({code: 'FI', displayName: 'פינלנד'}),
    15: Map({code: 'FR', displayName: 'צרפת'}),
    16: Map({code: 'GM', displayName: 'גמביה'}),
    17: Map({code: 'GE', displayName: 'גיאורגיה'}),
    18: Map({code: 'GH', displayName: 'גאנה'}),
    19: Map({code: 'GN', displayName: 'גינאה'}),
    20: Map({code: 'HT', displayName: 'האיטי'}),
    21: Map({code: 'ID', displayName: 'אינדונזיה'}),
    22: Map({code: 'IL', displayName: 'ישראל'}),
    23: Map({code: 'KE', displayName: 'קניה'}),
    24: Map({code: 'KG', displayName: 'קירגיסטן'}),
    25: Map({code: 'LV', displayName: 'לטביה'}),
    26: Map({code: 'LT', displayName: 'ליטא'}),
    27: Map({code: 'MO', displayName: 'מקאו'}),
    28: Map({code: 'ML', displayName: 'מאלי'}),
    29: Map({code: 'MD', displayName: 'מולדובה'}),
    30: Map({code: 'NL', displayName: 'הולנד'}),
    31: Map({code: 'NZ', displayName: 'ניו זילנד'}),
    32: Map({code: 'PY', displayName: 'פרגוואי'}),
    33: Map({code: 'PH', displayName: 'הפיליפינים'}),
    34: Map({code: 'PL', displayName: 'פולין'}),
    35: Map({code: 'RS', displayName: 'סרביה'}),
    36: Map({code: 'ZA', displayName: 'דרום אפריקה'}),
    37: Map({code: 'SE', displayName: 'שבדיה'}),
    38: Map({code: 'GB', displayName: 'בריטניה'}),
    39: Map({code: 'US', displayName: 'ארה"ב'}),
    40: Map({code: 'US', displayName: 'ארה"ב סים וירטואלי'}),
    41: Map({code: 'YE', displayName: 'תימן'})
})

var countries = Map({
    AF: 'AFGHANISTAN',
    AX: 'ALAND_ISLANDS',
    AL: 'ALBANIA',
    DZ: 'ALGERIA',
    AS: 'AMERICAN_SAMOA',
    AD: 'ANDORRA',
    AO: 'ANGOLA',
    AI: 'ANGUILLA',
    AQ: 'ANTARCTICA',
    AG: 'ANTIGUA_AND_BARBUDA',
    AR: 'ARGENTINA',
    AM: 'ARMENIA',
    AW: 'ARUBA',
    AU: 'AUSTRALIA',
    AT: 'AUSTRIA',
    AZ: 'AZERBAIJAN',
    BS: 'BAHAMAS',
    BH: 'BAHRAIN',
    BD: 'BANGLADESH',
    BB: 'BARBADOS',
    BY: 'BELARUS',
    BE: 'BELGIUM',
    BZ: 'BELIZE',
    BJ: 'BENIN',
    BM: 'BERMUDA',
    BT: 'BHUTAN',
    BO: 'BOLIVIA',
    BA: 'BOSNIA_AND_HERZEGOVINA',
    BW: 'BOTSWANA',
    BV: 'BOUVET_ISLAND',
    BR: 'BRAZIL',
    IO: 'BRITISH_INDIAN OCEAN TERRITORY',
    BN: 'BRUNEI_DARUSSALAM',
    BG: 'BULGARIA',
    BF: 'BURKINA_FASO',
    BI: 'BURUNDI',
    KH: 'CAMBODIA',
    CM: 'CAMEROON',
    CA: 'CANADA',
    CV: 'CAPE_VERDE',
    KY: 'CAYMAN_ISLANDS',
    CF: 'CENTRAL_AFRICAN_REPUBLIC',
    TD: 'CHAD',
    CL: 'CHILE',
    CN: 'CHINA',
    CX: 'CHRISTMAS_ISLAND',
    CC: 'COCOS_ISLANDS',
    CO: 'COLOMBIA',
    KM: 'COMOROS',
    CG: 'CONGO',
    CD: 'DEMOCRATIC_REPUBLIC_OF_CONGO',
    CK: 'COOK_ISLANDS',
    CR: 'COSTA_RICA',
    CI: 'COTE_DIVOIRE',
    HR: 'CROATIA',
    CU: 'CUBA',
    CY: 'CYPRUS',
    CZ: 'CZECH_REPUBLIC',
    DK: 'DENMARK',
    DJ: 'DJIBOUTI',
    DM: 'DOMINICA',
    DO: 'DOMINICAN_REPUBLIC',
    EC: 'ECUADOR',
    EG: 'EGYPT',
    SV: 'EL_SALVADOR',
    GQ: 'EQUATORIAL_GUINEA',
    ER: 'ERITREA',
    EE: 'ESTONIA',
    ET: 'ETHIOPIA',
    FK: 'FALKLAND_ISLANDS',
    FO: 'FAROE_ISLANDS',
    FJ: 'FIJI',
    FI: 'FINLAND',
    FR: 'FRANCE',
    GF: 'FRENCH_GUIANA',
    PF: 'FRENCH_POLYNESIA',
    TF: 'FRENCH_SOUTHERN_TERRITORIES',
    GA: 'GABON',
    GM: 'GAMBIA',
    GE: 'GEORGIA',
    DE: 'GERMANY',
    GH: 'GHANA',
    GI: 'GIBRALTAR',
    GR: 'GREECE',
    GL: 'GREENLAND',
    GD: 'GRENADA',
    GP: 'GUADELOUPE',
    GU: 'GUAM',
    GT: 'GUATEMALA',
    GG: 'GUERNSEY',
    GN: 'GUINEA',
    GW: 'GUINEA_BISSAU',
    GY: 'GUYANA',
    HT: 'HAITI',
    HM: 'HEARD_ISLAND_AND_MCDONALD_ISLANDS',
    VA: 'HOLY_SEE',
    HN: 'HONDURAS',
    HK: 'HONG KONG',
    HU: 'HUNGARY',
    IS: 'ICELAND',
    IN: 'INDIA',
    ID: 'INDONESIA',
    IR: 'IRAN',
    IQ: 'IRAQ',
    IE: 'IRELAND',
    IM: 'ISLE_OF_MAN',
    IL: 'ISRAEL',
    IT: 'ITALY',
    JM: 'JAMAICA',
    JP: 'JAPAN',
    JE: 'JERSEY',
    JO: 'JORDAN',
    KZ: 'KAZAKHSTAN',
    KE: 'KENYA',
    KI: 'KIRIBATI',
    KR: 'KOREA',
    KW: 'KUWAIT',
    KG: 'KYRGYZSTAN',
    LA: 'LAO_PEOPLES_DEMOCRATIC_REPUBLIC',
    LV: 'LATVIA',
    LB: 'LEBANON',
    LS: 'LESOTHO',
    LR: 'LIBERIA',
    LY: 'LIBYAN_ARAB_JAMAHIRIYA',
    LI: 'LIECHTENSTEIN',
    LT: 'LITHUANIA',
    LU: 'LUXEMBOURG',
    MO: 'MACAO',
    MK: 'MACEDONIA',
    MG: 'MADAGASCAR',
    MW: 'MALAWI',
    MY: 'MALAYSIA',
    MV: 'MALDIVES',
    ML: 'MALI',
    MT: 'MALTA',
    MH: 'MARSHALL_ISLANDS',
    MQ: 'MARTINIQUE',
    MR: 'MAURITANIA',
    MU: 'MAURITIUS',
    YT: 'MAYOTTE',
    MX: 'MEXICO',
    FM: 'MICRONESIA_FEDERATED_STATES_OF',
    MD: 'MOLDOVA',
    MC: 'MONACO',
    MN: 'MONGOLIA',
    ME: 'MONTENEGRO',
    MS: 'MONTSERRAT',
    MA: 'MOROCCO',
    MZ: 'MOZAMBIQUE',
    MM: 'MYANMAR',
    NA: 'NAMIBIA',
    NR: 'NAURU',
    NP: 'NEPAL',
    NL: 'NETHERLANDS',
    AN: 'NETHERLANDS_ANTILLES',
    NC: 'NEW_CALEDONIA',
    NZ: 'NEW_ZEALAND',
    NI: 'NICARAGUA',
    NE: 'NIGER',
    NG: 'NIGERIA',
    NU: 'NIUE',
    NF: 'NORFOLK_ISLAND',
    MP: 'NORTHERN_MARIANA_ISLANDS',
    NO: 'NORWAY',
    OM: 'OMAN',
    PK: 'PAKISTAN',
    PW: 'PALAU',
    PA: 'PANAMA',
    PG: 'PAPUA_NEW_GUINEA',
    PY: 'PARAGUAY',
    PE: 'PERU',
    PH: 'PHILIPPINES',
    PN: 'PITCAIRN',
    PL: 'POLAND',
    PT: 'PORTUGAL',
    PR: 'PUERTO_RICO',
    QA: 'QATAR',
    RE: 'REUNION',
    RO: 'ROMANIA',
    RU: 'RUSSIAN_FEDERATION',
    RW: 'RWANDA',
    BL: 'SAINT_BARTHELEMY',
    SH: 'SAINT_HELENA',
    KN: 'SAINT_KITTS AND NEVIS',
    LC: 'SAINT_LUCIA',
    MF: 'SAINT_MARTIN',
    PM: 'SAINT_PIERRE_AND_MIQUELON',
    VC: 'SAINT_VINCENT_AND_GRENADINES',
    WS: 'SAMOA',
    SM: 'SAN_MARINO',
    ST: 'SAO_TOME_AND_PRINCIPE',
    SA: 'SAUDI_ARABIA',
    SN: 'SENEGAL',
    RS: 'SERBIA',
    SC: 'SEYCHELLES',
    SL: 'SIERRA_LEONE',
    SG: 'SINGAPORE',
    SK: 'SLOVAKIA',
    SI: 'SLOVENIA',
    SB: 'SOLOMON_ISLANDS',
    SO: 'SOMALIA',
    ZA: 'SOUTH_AFRICA',
    GS: 'SOUTH_GEORGIA_AND_SANDWICH_ISL.',
    ES: 'SPAIN',
    LK: 'SRI_LANKA',
    SD: 'SUDAN',
    SR: 'SURINAME',
    SJ: 'SVALBARD_AND_JAN_MAYEN',
    SZ: 'SWAZILAND',
    SE: 'SWEDEN',
    CH: 'SWITZERLAND',
    SY: 'SYRIAN_ARAB_REPUBLIC',
    TW: 'TAIWAN',
    TJ: 'TAJIKISTAN',
    TZ: 'TANZANIA',
    TH: 'THAILAND',
    TL: 'TIMOR_LESTE',
    TG: 'TOGO',
    TK: 'TOKELAU',
    TO: 'TONGA',
    TT: 'TRINIDAD_AND_TOBAGO',
    TN: 'TUNISIA',
    TR: 'TURKEY',
    TM: 'TURKMENISTAN',
    TC: 'TURKS_AND_CAICOS_ISLANDS',
    TV: 'TUVALU',
    UG: 'UGANDA',
    UA: 'UKRAINE',
    AE: 'UNITED_ARAB_EMIRATES',
    GB: 'UNITED_KINGDOM',
    US: 'UNITED_STATES',
    USVS: 'USA_VIRTUAL_SIM',
    UM: 'UNITED_STATES_OUTLYING_ISLANDS',
    UY: 'URUGUAY',
    UZ: 'UZBEKISTAN',
    VU: 'VANUATU',
    VE: 'VENEZUELA',
    VN: 'VIET NAM',
    VG: 'VIRGIN_ISLANDS_BRITISH',
    VI: 'VIRGIN_ISLANDS_US',
    WF: 'WALLIS_AND_FUTUNA',
    EH: 'WESTERN_SAHARA',
    YE: 'YEMEN',
    ZM: 'ZAMBIA',
    ZW: 'ZIMBABWE'
})
const countriesToFlagObj = fromJS(Dictionary.phone_countries).map((displayName, index) => 
                                Map({code: getKey(fromJS(countries), getKey(fromJS(Globals.phone_countries), +index)), displayName}))

export {systemColorsPrimary};
// export {systemColorsSecondary};
export {translation};
export {fonts};
export {formatTypes};
export {extraPresses};
export {keyCodes};
export {screenSizes};
export {countriesIdToFlag};
export {Globals};
export {Dictionary};
export {buttonsColors};
export {Globals_details}
export {countriesToFlagObj}
export {regExps}