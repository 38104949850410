const joyrideReducer = (state={callback:()=>{}, steps:[]}, action) =>{
    switch(action.type){
        case("CHANGE_JOYRIDE"):{
            return({...state, callback: action.payload.callback, steps: action.payload.steps, run: action.payload.run})
        }
        default:{
            return (state);
        }
    }
}

export {joyrideReducer};