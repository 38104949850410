const generalReducer = (state={snackbarMessage: '', snackbarType: '', isSnackbarOpen: false, isSysOnline: true,
                               isStatsDialogOpen: false, statsDialogTitle: '', statsDialogContent: '', statsDialogComponent: 'div',
                               isConfirmationDialogOpen: false, confirmationDialogContent: '', confirmationDialogActions: '', confirmationDialogConfirm: ()=>{}}, action) =>{
    switch(action.type){
        case("SNACKBAR"):{
            return({...state,
                    snackbarMessage: action.payload.data.snackbarMessage,
                    snackbarType: action.payload.data.snackbarType,
                    isSnackbarOpen: action.payload.data.isSnackbarOpen})
        }
        case("SYS_MODE"):{
            return({...state, isSysOnline: action.payload})
        }
        case("STATS_DIALOG"):{
            return({...state,
                    statsDialogComponent : action.payload.data.statsDialogComponent,
                    isStatsDialogOpen: action.payload.data.isStatsDialogOpen,
                    statsDialogTitle: action.payload.data.statsDialogTitle,
                    statsDialogContent: action.payload.data.statsDialogContent})
        }
        case("CONFIRMATION_DIALOG"):{
            return({...state,
                    isConfirmationDialogOpen: action.payload.data.isConfirmationDialogOpen,
                    confirmationDialogContent: action.payload.data.confirmationDialogContent,
                    confirmationDialogConfirm: action.payload.data.confirmationDialogConfirm,
                    confirmationDialogActions: action.payload.data.confirmationDialogActions})
        }
        default:{
            return (state);
        }
    }
}

export {generalReducer};