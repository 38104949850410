import React, {Component} from "react";
import ErrorScreen from "./errorScreen";

class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, info) {
      // You can also log the error to an error reporting service
      // logErrorToMyService(error, info);
      console.log('error', error);
      console.log('info', info);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <ErrorScreen/>;
      }
  
      return this.props.children; 
    }
  }

  export default ErrorBoundary;