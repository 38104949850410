import React from "react";
import { Map, List } from 'immutable';
import { ViewModule, People, Photo, PowerSettingsNew, Computer, PhotoAlbum, CardMembership } from "@material-ui/icons";

const pages = List([
    Map({
        displayName: 'קמפיינים',
        name:'metekuold',
        url:'/campaigns',
        icon: <ViewModule/>
    }),
    Map({
        displayName: 'ניהול יוזרים',
        name:'agentsManagment',
        url:'/agentsManagment/agents',
        icon: <People/>
    }),
    Map({
        displayName: 'משאבים',
        name:'resources',
        url:'/resources',
        icon: <Photo/>
    }),
    Map({
        displayName: 'יוזרים',
        name:'agents',
        url:'/agentsManagment/agents',
        icon: <Computer/>
    }),
    Map({
        displayName: 'פרוקסים',
        name:'proxies',
        url:'/agentsManagment/proxies',
        icon: <Computer/>
    }),
    Map({
        displayName: 'אלבומים',
        name:'albums',
        url:'/agentsManagment/albums',
        icon: <PhotoAlbum/>
    }),
    Map({
        displayName: 'שמות',
        name:'names',
        url:'/agentsManagment/names',
        icon: <CardMembership />
    }),
    Map({
        displayName: 'התנתק',
        name:'login',
        url:'#/Login',
        icon: <PowerSettingsNew/>
    })
])

export {pages};