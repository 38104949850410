const usersReducer = (state={usersData: {}}, action) =>{
    switch(action.type){
        case("CHANGE_DATA_USERS"):{
            sessionStorage && action && action.data && 
                action.data.payload && sessionStorage.setItem('users', JSON.stringify(action.payload.data));
            return({...state, usersData: action.payload.data})
        }
        default:{
            return (state);
        }
    }
}

export {usersReducer};