import {combineReducers} from 'redux';
import {mainReducer} from './menuReducer';
import { campaignsReducer } from "./campaignsReducer";
import { usersReducer } from './usersReducer';
import { resourcesReducer } from './resourcesReducer';
import { generalReducer } from './generalReducer';
import { joyrideReducer } from './joyrideReducer';

const allReducers = combineReducers({
    general: generalReducer,
    main:mainReducer,
    campaigns:campaignsReducer,
    users: usersReducer,
    resources: resourcesReducer,
    joyride: joyrideReducer,
})

export default allReducers;