
const changeData = (data, type) => {
    return({
        type:`CHANGE_DATA_${type.toUpperCase()}`,
        payload:{data}
    })
}

const changeCampaignStatus = (data) => {
    return({
        type:`CHANGE_CAMPAIGN_STATUS`,
        payload: {data}
    });
}

const createSnackbar = (data, type) => {
    return({
        type: type,
        payload:{data}
    })
}

const changeSysMode = (data) => {
    return({
        type: 'SYS_MODE',
        payload: data
    })
}

const changeStatsDialogStatus = (data) => {
    return({
        type: 'STATS_DIALOG',
        payload: {data}
    })
}

const changeConfirmationDialogStatus = (data) => {
    return({
        type: 'CONFIRMATION_DIALOG',
        payload: {data}
    })
}

export {changeData};
export { changeCampaignStatus };
export {createSnackbar};
export {changeSysMode};
export {changeStatsDialogStatus};
export {changeConfirmationDialogStatus};
