const changeTitle = (title) => {
    return({
        type:"CHANGE_TITLE",
        payload:{title}
    })
}

const changeDatesTitle = (dates) => {
    return({
        type:"CHANGE_DATES_TITLE",
        payload:{dates}
    })
}

const changeSearchWordHandler = (searchWordHandler, searchInfo) => {
    return({
        type:"CHNAGE_SEARCH_AMOUNT_HANDLER",
        payload:{searchWordHandler, searchInfo}
    })
}

const changeSearcAmount = (searchAmount) => {
    return({
        type:"CHNAGE_SEARCH_AMOUNT",
        payload:{searchAmount}
    })
}

export {changeTitle};
export {changeDatesTitle};
export {changeSearchWordHandler};
export {changeSearcAmount};