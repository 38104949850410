import React, { Component } from 'react';
import { HashRouter, Route, Redirect } from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {changeTitle, changeDatesTitle} from './actions/menuActions/menuActions';
import {changeStatsDialogStatus} from './actions/mainActions';
import Login from './components/Login/Login';
import SystemStatusCollapse from "./components/customComponents/systemStatusCollapse";
const Vnc = React.lazy(_=> import(/* webpackChunkName: "Vnc" */'./components/customComponents/vnc')) 
const Menu = React.lazy(_=> import(/* webpackChunkName: "Menu" */'./components/menu/menu')) 
const AgentsMenu = React.lazy(_=> import(/* webpackChunkName: "Menu" */'./components/menu/agentsMenu')) 
const Users = React.lazy(_=> import(/* webpackChunkName: "Users" */'./components/users/users')) 
const Resources = React.lazy(_=> import(/* webpackChunkName: "Resources" */'./components/resources/resources')) 
const Proxies = React.lazy(_=> import(/* webpackChunkName: "Proxies" */'./components/proxies/proxies'));
const CampaignsFolders = React.lazy(_=> import(/* webpackChunkName: "CampaignsFolders" */'./components/campaigns/campaignsFolders')) 
const Albums = React.lazy(_=> import(/* webpackChunkName: "Albums" */'./components/albums/albums'))
const Names = React.lazy(_=> import('./components/names/names'));


class App extends Component {

  validateConnectivity = (component) => document.cookie.includes('session') ? <React.Suspense fallback={<div></div>}>{component}</React.Suspense> : <Redirect to="/Login"/>

  render() {
    const isMazi = process.env.REACT_APP_IS_MAZI==='true'

    return(
      <React.Fragment>
        <SystemStatusCollapse isOpen={!this.props.isSysOnline} />
        <HashRouter>
          <React.Fragment>
                <Route path="/" render={(path) => document.cookie === '' && <Redirect to="/Login"/>}/>
                <Route path="/" render={(path) => !window.location.href.includes('/vnc/') && this.validateConnectivity(<Menu isSysOnline={this.props.isSysOnline} title={this.props.title} datesTitle={this.props.datesTitle} path={path.location.pathname}/>)}/>
                <Route path="/agentsManagment" render={(path) => this.validateConnectivity(<AgentsMenu isSysOnline={this.props.isSysOnline} title={this.props.title} datesTitle={this.props.datesTitle} path={path.location.pathname}/>)}/>
                <Route path="/campaigns" component={path=> this.validateConnectivity(<CampaignsFolders isSysOnline={this.props.isSysOnline} path={path} changeStatsDialogStatus={this.props.changeStatsDialogStatus}/>)} />
                <Route exact path="/campaigns" component={path=> <Redirect to="/campaigns/ראשי"/>} />
                <Route exact path="/Login" component={Login} />
               {!isMazi&& <Route exact path="/agentsManagment/agents" component={path=> this.validateConnectivity(<Users isSysOnline={this.props.isSysOnline} path={path.location.pathname} changeStatsDialogStatus={this.props.changeStatsDialogStatus}/>)} />}
                <Route exact path="/resources" component={path=> this.validateConnectivity(<Resources isSysOnline={this.props.isSysOnline} path={path}/>)}/>
                {!isMazi && <Route exact path="/agentsManagment/proxies" component={path=> this.validateConnectivity(<Proxies isSysOnline={this.props.isSysOnline} path={path}/>)}/>}
                {!isMazi &&<Route path="/agentsManagment/albums" component={path=> this.validateConnectivity(<Albums isSysOnline={this.props.isSysOnline} path={path}/>)}/>}
                <Route path="/agentsManagment/names" component={path=> this.validateConnectivity(<Names isSysOnline={this.props.isSysOnline} path={path}/>)}/>
                <Route path="/vnc/:id" component={path=> this.validateConnectivity(<Vnc title={`MetekeAi - agent ${path.match.params.id}`} url={`ws://127.0.0.10:${5000 + (+path.match.params.id)}`}/>)}/>
                <Route exact path="/" render={_=> document.cookie !== '' && <Redirect to="/campaigns"/>}/>
                <Route exact path="/agentsManagment" render={_=> document.cookie !== '' && <Redirect to="/agentsManagment/agents"/>}/>
          </React.Fragment>
        </HashRouter>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return ({title: state.main.title,
           datesTitle: state.main.datesTitle,
           isSysOnline: state.general.isSysOnline})
}

const mapDispatchToProps = dispatch => {
  return (bindActionCreators({changeTitle, changeDatesTitle, changeStatsDialogStatus },dispatch))
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
