import { fromJS } from 'immutable'

const campaignsReducer = (state={campaignsData: []}, action) =>{
    switch(action.type){
        case("CHANGE_DATA_CAMPAIGNS"):{
            const immCampaignData = fromJS(state.campaignsData);
            const newCampaigns = fromJS(action.payload.data).map((group, key) => 
                                    group.update('campaigns', (old) => 
                                        old.map((campaign) => {
                                            return campaign.set('isLoading', !immCampaignData.isEmpty() && 
                                                                                immCampaignData.getIn([key, 'campaigns']) ? 
                                                                                immCampaignData.getIn([key, 'campaigns'])
                                                                                .filter(oldCampaign => oldCampaign.get('_id') === campaign.get('_id'))
                                                                                .getIn([0, 'isLoading']) :
                                                                                false)
                                        })))
            sessionStorage && action && action.data && 
                action.data.payload && sessionStorage.setItem('campaigns', JSON.stringify(action.payload.data));
            return({...state, campaignsData: newCampaigns.toJS()})
        }
        case("CHANGE_CAMPAIGN_STATUS"):{
            const data = action && action.payload && action.payload.data;
            const immCampaignData = fromJS(state.campaignsData);
            const new_campaigns = immCampaignData.map(group => 
                                    group.update('campaigns', campaigns => 
                                        campaigns.map((campaign, key) => 
                                            campaign.get('_id') === data._id ?
                                                campaign.update('isLoading', oldVal => data.isLoading).update('status_id', oldVal => data.status) :
                                                campaign)));
            sessionStorage && data && sessionStorage.setItem('campaigns', JSON.stringify(new_campaigns));
            return({...state, campaignsData: new_campaigns})
        }
        default:{
            return (state);
        }
    }
}

export {campaignsReducer};